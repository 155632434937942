export const navigation_all = {
  items: [
    {
      name: "รายงานสรุป",
      url: "/admin/dashboard",
      icon: "icon-speedometer",
    },

    {
      title: true,
      name: "จัดการพนักงาน",
      wrapper: {
        // optional wrapper object
        element: "", // required valid HTML5 element tag
        attributes: {}, // optional valid JS object with JS API naming ex: { className: "my-class", style: { fontFamily: "Verdana" }, id: "my-id"}
      },
      class: "", // optional class names space delimited list for title item ex: "text-center"
    },
    {
      name: "พนักงาน",
      url: "/admin/account",
      icon: "cui-file",
    },
    {
      title: true,
      name: "จัดการลูกค้า",
      wrapper: {
        // optional wrapper object
        element: "", // required valid HTML5 element tag
        attributes: {}, // optional valid JS object with JS API naming ex: { className: "my-class", style: { fontFamily: "Verdana" }, id: "my-id"}
      },
      class: "", // optional class names space delimited list for title item ex: "text-center"
    },
    {
      name: "ลูกค้า",
      url: "/admin/customer",
      icon: "cui-file",
    },
    {
      title: true,
      name: "จัดการสินค้า",
      wrapper: {
        // optional wrapper object
        element: "", // required valid HTML5 element tag
        attributes: {}, // optional valid JS object with JS API naming ex: { className: "my-class", style: { fontFamily: "Verdana" }, id: "my-id"}
      },
      class: "", // optional class names space delimited list for title item ex: "text-center"
    },
    {
      name: "สินค้า",
      url: "/admin/product",
      icon: "cui-file",
    },
    {
      title: true,
      name: "จัดการงานขาย",
      wrapper: {
        // optional wrapper object
        element: "", // required valid HTML5 element tag
        attributes: {}, // optional valid JS object with JS API naming ex: { className: "my-class", style: { fontFamily: "Verdana" }, id: "my-id"}
      },
      class: "", // optional class names space delimited list for title item ex: "text-center"
    },
    {
      name: "INVOICE",
      url: "/admin/invoice",
      icon: "cui-file",
    },
    {
      title: true,
      name: "การจัดการสต็อกสินค้า",
      wrapper: {
        // optional wrapper object
        element: "", // required valid HTML5 element tag
        attributes: {}, // optional valid JS object with JS API naming ex: { className: "my-class", style: { fontFamily: "Verdana" }, id: "my-id"}
      },
      class: "", // optional class names space delimited list for title item ex: "text-center"
    },
    {
      name: "Stock",
      url: "/admin/stock",
      icon: "cui-file",
    },
    {
      title: true,
      name: "รายงาน",
      wrapper: {
        // optional wrapper object
        element: "", // required valid HTML5 element tag
        attributes: {}, // optional valid JS object with JS API naming ex: { className: "my-class", style: { fontFamily: "Verdana" }, id: "my-id"}
      },
      class: "", // optional class names space delimited list for title item ex: "text-center"
    },
    {
      name: "รายงาน Stock",
      url: "/admin/report-stock",
      icon: "cui-file",
    },
  ],
};
export const navigation_sale = {
  items: [
    {
      title: true,
      name: "จัดการลูกค้า",
      wrapper: {
        // optional wrapper object
        element: "", // required valid HTML5 element tag
        attributes: {}, // optional valid JS object with JS API naming ex: { className: "my-class", style: { fontFamily: "Verdana" }, id: "my-id"}
      },
      class: "", // optional class names space delimited list for title item ex: "text-center"
    },
    {
      name: "ลูกค้า",
      url: "/admin/customer",
      icon: "cui-file",
    },
    {
      title: true,
      name: "จัดการงานขาย",
      wrapper: {
        // optional wrapper object
        element: "", // required valid HTML5 element tag
        attributes: {}, // optional valid JS object with JS API naming ex: { className: "my-class", style: { fontFamily: "Verdana" }, id: "my-id"}
      },
      class: "", // optional class names space delimited list for title item ex: "text-center"
    },
    {
      name: "INVOICE",
      url: "/admin/invoice",
      icon: "cui-file",
    },
    {
      title: true,
      name: "การจัดการสต็อกสินค้า",
      wrapper: {
        // optional wrapper object
        element: "", // required valid HTML5 element tag
        attributes: {}, // optional valid JS object with JS API naming ex: { className: "my-class", style: { fontFamily: "Verdana" }, id: "my-id"}
      },
      class: "", // optional class names space delimited list for title item ex: "text-center"
    },
    {
      name: "Stock",
      url: "/admin/stock",
      icon: "cui-file",
    },
    {
      title: true,
      name: "รายงาน",
      wrapper: {
        // optional wrapper object
        element: "", // required valid HTML5 element tag
        attributes: {}, // optional valid JS object with JS API naming ex: { className: "my-class", style: { fontFamily: "Verdana" }, id: "my-id"}
      },
      class: "", // optional class names space delimited list for title item ex: "text-center"
    },
    {
      name: "รายงาน Stock",
      url: "/admin/report-stock",
      icon: "cui-file",
    },
  ],
};
export const navigation_stock = {
  items: [
    {
      title: true,
      name: "จัดการสินค้า",
      wrapper: {
        // optional wrapper object
        element: "", // required valid HTML5 element tag
        attributes: {}, // optional valid JS object with JS API naming ex: { className: "my-class", style: { fontFamily: "Verdana" }, id: "my-id"}
      },
      class: "", // optional class names space delimited list for title item ex: "text-center"
    },
    {
      name: "สินค้า",
      url: "/admin/product",
      icon: "cui-file",
    },
  ],
};
