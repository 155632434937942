import React from "react";
import { reaction } from "mobx";

const Dashboard = React.lazy(() =>
  import("./views/Pages/Dashboard/DashBoardScene")
);
const OverallScene = React.lazy(() =>
  import("./views/Dashboard/DashboardScene")
);
const CrateAccountScene = React.lazy(() =>
  import("./views/Pages/Account/CreateAccountScene")
);
const DashBoardAccountScene = React.lazy(() =>
  import("./views/Pages/Account/DashBoardAccountScene")
);
const DetailAccountScene = React.lazy(() =>
  import("./views/Pages/Account/DetailAccountScene")
);
const DashBoardCustomerScene = React.lazy(() =>
  import("./views/Pages/Customer/DashBoardCustomerScene")
);
const DetailCustomerScene = React.lazy(() =>
  import("./views/Pages/Customer/DetailCustomerScene")
);
const DashBoardProductScene = React.lazy(() =>
  import("./views/Pages/Product/DashBoardProductScene")
);
const ChangPasswordScene = React.lazy(() =>
  import("./views/Users/ChangpasswordScene")
);
const ProfileUserScene = React.lazy(() =>
  import("./views/Pages/User/ProfileUserScene")
);
const CreateProductScene = React.lazy(() =>
  import("./views/Pages/Product/CreateProductScene")
);
const DetailProductScene = React.lazy(() =>
  import("./views/Pages/Product/DetailProductScene")
);
const DashBoardInvoiceScene = React.lazy(() =>
  import("./views/Pages/Invoice/DashBoardInvoiceScene")
);
const CreateInvoiceScene = React.lazy(() =>
  import("./views/Pages/Invoice/CreateInvoiceScene")
);
const DetailInvoiceScene = React.lazy(() =>
  import("./views/Pages/Invoice/DetailInvoiceScene")
);
const StockScene = React.lazy(() => import("./views/Pages/Stock/SotckScene"));
const ReceiveStockScene = React.lazy(() =>
  import("./views/Pages/Stock/ReceiveSotckScene")
);
const TransferSotckScene = React.lazy(() =>
  import("./views/Pages/Stock/TransferSotckScene")
);
const ReportStockScene = React.lazy(() =>
  import("./views/Pages/Stock/ReportStockScene")
);
const ReportStockDetailScene = React.lazy(() =>
  import("./views/Pages/Stock/ReportStockDetailScene")
);
const widget = React.lazy(() => import("./views/Widgets/Widgets"));
const routes = [
  { path: "/home", exact: true, name: "Home" },
  {
    path: "/admin/dashboard",
    exact: true,
    name: "Dashboard",
    component: Dashboard,
  },
  { path: "/overall", exact: true, name: "Overall", component: OverallScene },
  {
    path: "/admin/account",
    exact: true,
    name: "Account",
    component: DashBoardAccountScene,
  },
  {
    path: "/admin/account/create_account",
    name: "CreateAccount",
    component: CrateAccountScene,
  },
  {
    path: "/admin/account/detail_account/:account_id",
    name: "DetailAccount",
    component: DetailAccountScene,
  },
  {
    path: "/admin/customer",
    exact: true,
    name: "Customer",
    component: DashBoardCustomerScene,
  },
  {
    path: "/admin/customer/detail_customer/:customer_id",
    name: "DetailCustomer",
    component: DetailCustomerScene,
  },

  {
    path: "/changpassword",
    exact: true,
    name: "Changpassword",
    component: ChangPasswordScene,
  },
  {
    path: "/admin/Profile",
    exact: true,
    name: "Profile",
    component: ProfileUserScene,
  },
  {
    path: "/admin/product",
    exact: true,
    name: "Product",
    component: DashBoardProductScene,
  },
  {
    path: "/admin/product/create_product",
    name: "CreateProduct",
    component: CreateProductScene,
  },
  {
    path: "/admin/product/detail_product/:product_id",
    name: "DetailProduct",
    component: DetailProductScene,
  },

  {
    path: "/admin/invoice",
    exact: true,
    name: "Invoice",
    component: DashBoardInvoiceScene,
  },
  {
    path: "/admin/invoice/create_invoice",
    name: "CreateInvoice",
    component: CreateInvoiceScene,
  },
  {
    path: "/admin/invoice/detail_invoice/:invoice_id",
    name: "DetailInvoice",
    component: DetailInvoiceScene,
  },
  { path: "/admin/widget", exact: true, name: "widget", component: widget },
  {
    path: "/admin/stock",
    exact: true,
    name: (
      <div>
        <text style={{ color: "#51A6D3" }}>Home</text> / Stock
      </div>
    ),
    component: StockScene,
  },
  {
    path: "/admin/stock/receive-stock",
    exact: true,
    name: "รายการรับเข้า",
    component: ReceiveStockScene,
  },
  {
    path: "/admin/stock/transfer-stock",
    exact: true,
    name: "รายการจ่ายออก",
    component: TransferSotckScene,
  },
  {
    path: "/admin/report-stock",
    exact: true,
    name: (
      <div>
        <text style={{ color: "#51A6D3" }}>Home</text> / รายงานสต็อกสินค้า
      </div>
    ),
    component: ReportStockScene,
  },
  {
    path: "/admin/report-stock/report-stock-detail",
    exact: true,
    name: "รายละเอียดสินค้าคงเหลือ",
    component: ReportStockDetailScene,
  },
];

export default routes;
